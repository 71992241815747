import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import PhoneInputController from 'controller/PhoneInputController';
// constants, style, interfaces
import { ProfileCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  EMAIL_TEXT,
  FIRST_NAME_TEXT,
  LAST_NAME_TEXT,
  ORGANIZATION_NAME_TEXT,
  PHONE_TEXT,
  SAVE_TEXT,
} from 'constants/index';

const ProfileCard: FC<ProfileCardProps> = ({ loading }) => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={4} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputController fieldType="text" title={ORGANIZATION_NAME_TEXT} name="orgName" disabled />
          </Grid>
          <Grid item xs={12} xl={6}>
            <InputController
              fieldType="text"
              title={FIRST_NAME_TEXT}
              isRequired
              name="firstName"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <InputController
              fieldType="text"
              title={LAST_NAME_TEXT}
              isRequired
              name="lastName"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} xl={12}>
            <InputController fieldType="email" title={EMAIL_TEXT} name="email" disabled />
          </Grid>
          <Grid item xs={12} xl={12}>
            <PhoneInputController name="phone" title={PHONE_TEXT} disabled={loading} />
          </Grid>
        </Grid>
      )}
      <Box sx={flexMarginTopEndCenter}>
        <Button type="submit" variant="contained" size="small" disabled={loading}>
          {SAVE_TEXT}
        </Button>
      </Box>
    </Card>
  );
};

export default ProfileCard;
