// imports
import { memo, FC } from 'react';
import { Chip, Box, Typography } from '@mui/material';
// constants, interfaces
import { ACTIVE_TEXT, INACTIVE_TEXT } from 'constants/index';
import { RenderOptionProps } from 'interfaces';

const RenderOption: FC<RenderOptionProps> = (props) => {
  const { lastElementRef, liProps, option } = props || {};
  const { name, isActive, value: val, description } = option || {};
  return (
    <Box component="li" sx={{ '& > div': { width: '100%' } }} key={val} ref={lastElementRef} {...liProps}>
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant="caption">{name || '--'}</Typography>
          {typeof isActive === 'boolean' && (
            <Chip
              size="small"
              variant="outlined"
              label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
              color={isActive ? 'success' : 'error'}
            />
          )}
        </Box>
        {description && (
          <Typography color="primary" fontStyle={'italic'}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default memo(RenderOption);
