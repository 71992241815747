import {
  FC,
  Reducer,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useReducer,
  useCallback,
  SyntheticEvent,
} from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import EditGeneral from './EditGeneral';
import { Alert } from 'components/common/Alert';
import TabPanel from 'components/common/TabPanel';
import Nutrients from '../../Ingredients/Add/components/Nutrients';
import HeavyMetals from '../../Ingredients/Add/components/HeavyMetals';

// interfaces , constants , validationSchema, graphql, reducer, helpers
import { formatEditIngredientInfo } from 'lib/helper';
import {
  HTTP_STATUS,
  nutrientsInitialValues,
  heavyMetalsInitialValues,
  SyrupBaseIngredientTabsEnum,
  SYRUP_BASE_INGREDIENT_TABS,
} from 'constants/index';
import { NutrientsCardType, HeavyMetalsFormType, EditIngredientFormProps } from 'interfaces';
import { nutrientsSchema, heavyMetalSchema } from 'validationSchema';
import { useUpdateIngredientInfoMutation } from 'generated/graphql';
import {
  Action,
  ActionType,
  State,
  syrupBaseIngredientReducer,
  initialState,
} from 'reducer/SyrupBaseIngredient';

const EditIngredientForm: FC<EditIngredientFormProps> = ({
  ingredient,
  fetchIngredient,
  loading: getLoading,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(syrupBaseIngredientReducer, initialState);
  const { activeTab } = state;
  const { ingredientInfo } = ingredient || {};
  const { ingredientId } = ingredientInfo || {};

  const tabHandler = (_: SyntheticEvent, activeTab: SyrupBaseIngredientTabsEnum) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab });
  };

  const nutrientMethods = useForm<NutrientsCardType>({
    defaultValues: nutrientsInitialValues,
    resolver: yupResolver(nutrientsSchema),
  });

  const heavyMetalsMethods = useForm<HeavyMetalsFormType>({
    defaultValues: heavyMetalsInitialValues,
    resolver: yupResolver(heavyMetalSchema),
  });

  const { handleSubmit: handleNutrientSubmit, setValue: setNutrientValue } = nutrientMethods;
  const { handleSubmit: handleHeavyMetalSubmit, setValue: setHeavyMetalValue } = heavyMetalsMethods;

  const [updateIngredientInfo, { loading: updateIngredientInfoLoading }] = useUpdateIngredientInfoMutation({
    onCompleted: (data) => {
      const { updateIngredientInfo } = data;
      const { response } = updateIngredientInfo || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onNutrientSubmit: SubmitHandler<NutrientsCardType> = async (data) => {
    if (ingredientId) {
      const ingredientInfoObject = formatEditIngredientInfo(data, ingredientId);
      await updateIngredientInfo({
        variables: {
          updateIngredientInfoInput: ingredientInfoObject,
        },
      });
    }
  };

  const onHeavyMetalSubmit: SubmitHandler<HeavyMetalsFormType> = async (data) => {
    const { arsenic, cadmium, lead, mercury } = data || {};

    if (ingredientId) {
      await updateIngredientInfo({
        variables: {
          updateIngredientInfoInput: {
            ingredientId: ingredientId,
            arsenic,
            cadmium,
            lead,
            mercury,
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const { ingredientInfo } = ingredient || {};

    const {
      addedSugar,
      arsenic,
      biotin,
      boron,
      cadmium,
      calcium,
      calories,
      carbohydrates,
      cholesterol,
      choline,
      dietaryFiber,
      folate,
      iodine,
      iron,
      lead,
      magnesium,
      mercury,
      moisture,
      monounsaturatedFat,
      niacin,
      organic,
      polyunsaturatedFat,
      potassium,
      protein,
      riboflavin,
      saturatedFat,
      sodium,
      thiamin,
      totalFat,
      totalSugars,
      transFat,
      vitaminA,
      vitaminB12,
      vitaminB6,
      vitaminC,
      vitaminD,
      vitaminE,
      vitaminK2,
      zinc,
      chloride,
      copper,
      chromium,
      manganese,
      molybdenum,
      pantothenicAcid,
      phosphorus,
      selenium,
    } = ingredientInfo || {};

    // setting nutrients values
    lead && setHeavyMetalValue('lead', `${lead}`);
    arsenic && setHeavyMetalValue('arsenic', `${arsenic}`);
    mercury && setHeavyMetalValue('mercury', `${mercury}`);
    cadmium && setHeavyMetalValue('cadmium', `${cadmium}`);
    addedSugar && setNutrientValue('ingredientInfo.addedSugar', `${addedSugar}`);
    organic && setNutrientValue('ingredientInfo.organic', `${organic}`);
    biotin && setNutrientValue('ingredientInfo.biotin', `${biotin}`);
    boron && setNutrientValue('ingredientInfo.boron', `${boron}`);
    calcium && setNutrientValue('ingredientInfo.calcium', `${calcium}`);
    cholesterol && setNutrientValue('ingredientInfo.cholesterol', `${cholesterol}`);
    carbohydrates && setNutrientValue('ingredientInfo.carbohydrates', `${carbohydrates}`);
    choline && setNutrientValue('ingredientInfo.choline', `${choline}`);
    dietaryFiber && setNutrientValue('ingredientInfo.dietaryFiber', `${dietaryFiber}`);
    folate && setNutrientValue('ingredientInfo.folate', `${folate}`);
    iodine && setNutrientValue('ingredientInfo.iodine', `${iodine}`);
    iron && setNutrientValue('ingredientInfo.iron', `${iron}`);
    magnesium && setNutrientValue('ingredientInfo.magnesium', `${magnesium}`);
    moisture && setNutrientValue('ingredientInfo.moisture', `${moisture}`);
    monounsaturatedFat && setNutrientValue('ingredientInfo.monounsaturatedFat', `${monounsaturatedFat}`);
    niacin && setNutrientValue('ingredientInfo.niacin', `${niacin}`);
    polyunsaturatedFat && setNutrientValue('ingredientInfo.polyunsaturatedFat', `${polyunsaturatedFat}`);
    potassium && setNutrientValue('ingredientInfo.potassium', `${potassium}`);
    protein && setNutrientValue('ingredientInfo.protein', `${protein}`);
    riboflavin && setNutrientValue('ingredientInfo.riboflavin', `${riboflavin}`);
    saturatedFat && setNutrientValue('ingredientInfo.saturatedFat', `${saturatedFat}`);
    sodium && setNutrientValue('ingredientInfo.sodium', `${sodium}`);
    calories && setNutrientValue('ingredientInfo.calories', `${calories}`);
    thiamin && setNutrientValue('ingredientInfo.thiamin', `${thiamin}`);
    totalFat && setNutrientValue('ingredientInfo.totalFat', `${totalFat}`);
    totalSugars && setNutrientValue('ingredientInfo.totalSugars', `${totalSugars}`);
    transFat && setNutrientValue('ingredientInfo.transFat', `${transFat}`);
    vitaminA && setNutrientValue('ingredientInfo.vitaminA', `${vitaminA}`);
    vitaminB12 && setNutrientValue('ingredientInfo.vitaminB12', `${vitaminB12}`);
    vitaminB6 && setNutrientValue('ingredientInfo.vitaminB6', `${vitaminB6}`);
    vitaminC && setNutrientValue('ingredientInfo.vitaminC', `${vitaminC}`);
    vitaminD && setNutrientValue('ingredientInfo.vitaminD', `${vitaminD}`);
    vitaminE && setNutrientValue('ingredientInfo.vitaminE', `${vitaminE}`);
    vitaminK2 && setNutrientValue('ingredientInfo.vitaminK2', `${vitaminK2}`);
    zinc && setNutrientValue('ingredientInfo.zinc', `${zinc}`);
    chloride && setNutrientValue('ingredientInfo.chloride', `${chloride}`);
    selenium && setNutrientValue('ingredientInfo.selenium', `${selenium}`);
    molybdenum && setNutrientValue('ingredientInfo.molybdenum', `${molybdenum}`);
    chromium && setNutrientValue('ingredientInfo.chromium', `${chromium}`);
    copper && setNutrientValue('ingredientInfo.copper', `${copper}`);
    phosphorus && setNutrientValue('ingredientInfo.phosphorus', `${phosphorus}`);
    manganese && setNutrientValue('ingredientInfo.manganese', `${manganese}`);
    pantothenicAcid && setNutrientValue('ingredientInfo.pantothenicAcid', `${pantothenicAcid}`);
  }, [ingredient, setHeavyMetalValue, setNutrientValue]);

  useEffect(() => {
    ingredient && setValues();
  }, [setValues, ingredient]);

  const loading = isLoading || getLoading || updateIngredientInfoLoading;

  const tabMappedData = useMemo(() => {
    return SYRUP_BASE_INGREDIENT_TABS;
  }, []);

  return (
    <Fragment>
      <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
        {tabMappedData?.map((item) => {
          const { name, value } = item;
          return <Tab value={value} key={value} label={name} iconPosition="start" />;
        })}
      </Tabs>

      <Box pt={6}>
        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.GENERAL}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <EditGeneral
                fetchIngredient={fetchIngredient}
                ingredient={ingredient}
                setIsLoading={setIsLoading}
              />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.NUTRIENTS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...nutrientMethods}>
                <form onSubmit={handleNutrientSubmit(onNutrientSubmit)}>
                  <Nutrients loading={loading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.HEAVY_METALS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <FormProvider {...heavyMetalsMethods}>
                <form onSubmit={handleHeavyMetalSubmit(onHeavyMetalSubmit)}>
                  <HeavyMetals loading={getLoading} isEdit />
                </form>
              </FormProvider>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default EditIngredientForm;
