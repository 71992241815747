import { FC, MouseEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, List, ListItem, Popover } from '@mui/material';
// styles, interfaces
import { Nullable } from 'interfaces';
import { AuthContext } from 'contexts/AuthContext';
import { avatarBox, cursorPointer, popoverListItemLabel } from 'styles/commonComponentStyle';
import { PROFILE_ROUTE, PROFILE_TEXT, SETTINGS_ROUTE, SETTINGS_TEXT } from 'constants/index';

const UserProfilePopover: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const { firstName, lastName } = currentUser || {};

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton sx={avatarBox} onClick={handleClick}>
        {firstName?.charAt(0) || ''} {lastName?.charAt(0) || ''}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={popoverListItemLabel}>
        <List>
          <ListItem onClick={() => navigate(PROFILE_ROUTE)} sx={cursorPointer}>
            {PROFILE_TEXT}
          </ListItem>

          <ListItem onClick={() => navigate(SETTINGS_ROUTE)} sx={cursorPointer}>
            {SETTINGS_TEXT}
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default UserProfilePopover;
