import { SelectType } from 'interfaces';
import { selectTypeInitialValues } from 'constants/index';
import { FormulasIngredientPayload } from 'generated/graphql';

export type State = {
  labels: string[];
  top: string;
  year: string;
  open: boolean;
  toDate: string;
  fromDate: string;
  pieCanvas: string;
  lineCanvas: string;
  ingredients: SelectType[];
  ingredientUsage: SelectType[];
  productType: SelectType;
  dosageType: SelectType;
  supplier: SelectType;
  organization: SelectType;
  subProductType: SelectType;
  formulationType: SelectType;
  data: FormulasIngredientPayload['data'];
};

export const initialState: State = {
  labels: [],
  year: `${new Date().getFullYear()}`,
  top: '10',
  data: [],
  toDate: '',
  fromDate: '',
  productType: selectTypeInitialValues,
  organization: selectTypeInitialValues,
  subProductType: selectTypeInitialValues,
  ingredients: [],
  ingredientUsage: [],
  dosageType: selectTypeInitialValues,
  formulationType: selectTypeInitialValues,
  supplier: selectTypeInitialValues,
  open: false,
  pieCanvas: '',
  lineCanvas: '',
};

export enum ActionType {
  SET_LABELS = 'setLabels',
  SET_SUPPLIER = 'setSupplier',
  SET_TOP = 'setTop',
  SET_YEAR = 'setYear',
  SET_OPEN = 'setOpen',
  SET_DATA = 'setData',
  SET_TO_DATE = 'setToDate',
  SET_FROM_DATE = 'setFromDate',
  SET_INGREDIENT = 'setIngredient',
  SET_INGREDIENT_USAGE = 'setIngredientUsage',
  SET_PRODUCT_TYPE = 'setProductType',
  SET_ORGANIZATION = 'setOrganization',
  SET_SUB_PRODUCT_TYPE = 'setSubProductType',
  SET_PIE_CANVAS = 'setPieCanvas',
  SET_LINE_CANVAS = 'setLineCanvas',
  SET_DOSAGE_TYPE = 'setDosageType',
  SET_FORMULATION_TYPE = 'setFormulationType',
}

export type Action =
  | { type: ActionType.SET_YEAR; year: State['year'] }
  | { type: ActionType.SET_OPEN; open: State['open'] }
  | { type: ActionType.SET_DATA; data: State['data'] }
  | { type: ActionType.SET_TO_DATE; toDate: State['toDate'] }
  | { type: ActionType.SET_FROM_DATE; fromDate: State['fromDate'] }
  | { type: ActionType.SET_PIE_CANVAS; pieCanvas: State['pieCanvas'] }
  | { type: ActionType.SET_INGREDIENT; ingredients: State['ingredients'] }
  | { type: ActionType.SET_LINE_CANVAS; lineCanvas: State['lineCanvas'] }
  | { type: ActionType.SET_PRODUCT_TYPE; productType: State['productType'] }
  | { type: ActionType.SET_ORGANIZATION; organization: State['organization'] }
  | { type: ActionType.SET_SUB_PRODUCT_TYPE; subProductType: State['subProductType'] }
  | { type: ActionType.SET_INGREDIENT_USAGE; ingredientUsage: State['ingredientUsage'] }
  | { type: ActionType.SET_DOSAGE_TYPE; dosageType: State['dosageType'] }
  | { type: ActionType.SET_FORMULATION_TYPE; formulationType: State['formulationType'] }
  | { type: ActionType.SET_TOP; top: State['top'] }
  | { type: ActionType.SET_SUPPLIER; supplier: State['supplier'] }
  | { type: ActionType.SET_LABELS; labels: State['labels'] };

export const ingredientReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_OPEN:
      return {
        ...state,
        open: action.open,
      };

    case ActionType.SET_DATA:
      return {
        ...state,
        data: action.data,
      };

    case ActionType.SET_SUB_PRODUCT_TYPE:
      return {
        ...state,
        subProductType: action.subProductType,
      };

    case ActionType.SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.productType,
      };

    case ActionType.SET_ORGANIZATION:
      return {
        ...state,
        organization: action.organization,
      };

    case ActionType.SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      };

    case ActionType.SET_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      };

    case ActionType.SET_PIE_CANVAS:
      return {
        ...state,
        pieCanvas: action.pieCanvas,
      };

    case ActionType.SET_LINE_CANVAS:
      return {
        ...state,
        lineCanvas: action.lineCanvas,
      };

    case ActionType.SET_YEAR:
      return {
        ...state,
        year: action.year,
      };

    case ActionType.SET_INGREDIENT:
      return {
        ...state,
        ingredients: action.ingredients,
      };

    case ActionType.SET_INGREDIENT_USAGE:
      return {
        ...state,
        ingredientUsage: action.ingredientUsage,
      };

    case ActionType.SET_DOSAGE_TYPE:
      return {
        ...state,
        dosageType: action.dosageType,
      };

    case ActionType.SET_FORMULATION_TYPE:
      return {
        ...state,
        formulationType: action.formulationType,
      };

    case ActionType.SET_TOP:
      return {
        ...state,
        top: action.top,
      };

    case ActionType.SET_SUPPLIER:
      return {
        ...state,
        supplier: action.supplier,
      };

    case ActionType.SET_LABELS:
      return {
        ...state,
        labels: action.labels,
      };
  }
};
