import { HttpStatusCode } from 'axios';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { FC, Fragment, JSX, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import FormCard from './FormCard';
import { Alert } from 'components/common/Alert';
import DataLoader from 'components/common/DataLoader';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import {
  FormulationSyrupUnit,
  ProductFormulationSyrupTypePayload,
  useGetProductFormulationSyrupTypeLazyQuery,
  useUpdateProductFormulationSyrupTypeMutation,
} from 'generated/graphql';
import { formulationSyrupTypeSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { useUpdateLoadingText, useUpdateTitleText } from 'hooks';
import { FormulationSyrupTypeFormType, ParamType } from 'interfaces';
import { FORMULATION_SYRUP_TYPE_TEXT, formulationSyrupTypeInitialValue } from 'constants/index';

const Edit: FC = (): JSX.Element => {
  const navigation = useNavigate();
  const params = useParams<ParamType>();
  const { id } = params || {};

  const editText = useUpdateTitleText(FORMULATION_SYRUP_TYPE_TEXT);
  const updatingText = useUpdateLoadingText(FORMULATION_SYRUP_TYPE_TEXT);

  const methods = useForm<FormulationSyrupTypeFormType>({
    defaultValues: formulationSyrupTypeInitialValue,
    resolver: yupResolver(formulationSyrupTypeSchema),
  });

  const { handleSubmit, setValue } = methods;

  const [updateFormulationSyrupType, { loading }] = useUpdateProductFormulationSyrupTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateProductFormulationSyrupType } = data;
      const { response } = updateProductFormulationSyrupType || {};
      const { status, message } = response || {};
      if (status === HttpStatusCode.Ok) {
        Alert.success(message || '');
        navigation(-1);
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [getFormulationSyrupType, { loading: getLoading }] = useGetProductFormulationSyrupTypeLazyQuery({
    onCompleted: (data) => {
      const { getProductFormulationSyrupType } = data || {};
      const { response, productFormulationSyrupType } = getProductFormulationSyrupType || {};
      const { status } = response || {};
      if (status === HttpStatusCode.Ok) {
        setValues(
          productFormulationSyrupType as ProductFormulationSyrupTypePayload['productFormulationSyrupType'],
        );
      }
    },
    onError: () => {
      setValues(null);
    },
  });

  const onSubmit: SubmitHandler<FormulationSyrupTypeFormType> = async (data) => {
    const { cost, weight, productType, productSize, subProductType, productFormulationType, name } =
      data || {};

    const { value: productTypeId } = productType || {};
    const { value: productSizeId } = productSize || {};
    const { value: subProductTypeId } = subProductType || {};
    const { value: productFormulationId } = productFormulationType || {};

    if (id) {
      await updateFormulationSyrupType({
        variables: {
          updateProductFormulationSyrupTypeInput: {
            id,
            name,
            productTypeId,
            productSizeId,
            cost: Number(cost),
            weight: Number(weight),
            unit: FormulationSyrupUnit.Mg,
            productFormulationTypeId: productFormulationId,
            ...(subProductTypeId && { subProductTypeId }),
          },
        },
      });
    }
  };

  const fetchFormulationSyrupType = useCallback(async () => {
    id &&
      (await getFormulationSyrupType({
        variables: {
          getProductFormulationSyrupTypeInput: {
            id,
          },
        },
      }));
  }, [id, getFormulationSyrupType]);

  const setValues = (params: ProductFormulationSyrupTypePayload['productFormulationSyrupType']) => {
    const { cost, weight, productType, productSize, subProductType, productFormulationType, name } =
      params || {};

    const { id: productTypeId, name: productTypeName } = productType || {};
    const { id: productSizeId, name: productSizeName } = productSize || {};
    const { id: subProductTypeId, name: subProductTypeName } = subProductType || {};
    const { id: productFormulationId, name: productFormulationTypeName } = productFormulationType || {};

    setValue('name', name ?? '');
    setValue('cost', `${cost ?? 0}`);
    setValue('weight', `${weight ?? 0}`);
    setValue('productFormulationType', {
      value: productFormulationId ?? '',
      name: productFormulationTypeName ?? '',
    });
    setValue('productSize', {
      value: productSizeId ?? '',
      name: productSizeName ?? '',
    });

    setValue('subProductType', {
      value: subProductTypeId ?? '',
      name: subProductTypeName ?? '',
    });
    setValue('productType', {
      value: productTypeId ?? '',
      name: productTypeName ?? '',
    });
  };

  useEffect(() => {
    id && fetchFormulationSyrupType();
  }, [id, fetchFormulationSyrupType]);

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{editText}</Typography>
          </Box>
          {getLoading ? <DataLoader columns={12} rows={6} /> : <FormCard loading={loading} isEdit />}
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={updatingText} />
    </Fragment>
  );
};

export default Edit;
