import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, Card, Button, Box } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import StateSelector from 'components/common/selectors/state';
import CountrySelector from 'components/common/selectors/country';
// constants
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import {
  ADDRESS_1_TEXT,
  ADDRESS_2_TEXT,
  CITY_TEXT,
  COUNTRY_TEXT,
  SAVE_TEXT,
  selectTypeInitialValues,
  STATE_TEXT,
  ZIP_CODE_TEXT,
} from 'constants/index';
// interfaces
import { AddressCardProps, AddressFormType } from 'interfaces';

const Address: FC<AddressCardProps> = ({ loading = false, isEdit = false }) => {
  const { watch, setValue } = useFormContext<AddressFormType>();
  const { country } = watch();
  const { value: countryCode } = country || {};

  const resetCountryValueOnChange = () => {
    setValue('state', selectTypeInitialValues);
  };

  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={4} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputController fieldType="text" title={ADDRESS_1_TEXT} name="address1" disabled={loading} />
          </Grid>
          <Grid item xs={12}>
            <InputController fieldType="text" title={ADDRESS_2_TEXT} name="address2" disabled={loading} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <InputController fieldType="text" title={CITY_TEXT} name="city" disabled={loading} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <InputController fieldType="text" title={ZIP_CODE_TEXT} name="zip" disabled={loading} />
          </Grid>

          <Grid item xs={12} lg={6}>
            <CountrySelector
              name="country"
              title={COUNTRY_TEXT}
              disabled={loading}
              handleChange={resetCountryValueOnChange}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <StateSelector
              disabled={!!(loading || !countryCode)}
              name="state"
              title={STATE_TEXT}
              countryCode={countryCode}
            />
          </Grid>
        </Grid>
      )}

      {isEdit && (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default Address;
