import { Box, Grid, Tab, Tabs } from '@mui/material';
import { FC, Fragment, Reducer, SyntheticEvent, useMemo, useReducer } from 'react';
// components
import General from './components/General';
import TabPanel from 'components/common/TabPanel';
import Nutrients from '../../Ingredients/Add/components/Nutrients';
import HeavyMetals from '../../Ingredients/Add/components/HeavyMetals';
// interfaces, constants, reducer
import { isLoadingType } from 'interfaces';
import { SYRUP_BASE_INGREDIENT_TABS, SyrupBaseIngredientTabsEnum } from 'constants/index';
import {
  Action,
  ActionType,
  State,
  syrupBaseIngredientReducer,
  initialState,
} from 'reducer/SyrupBaseIngredient';

const SyrupBaseIngredientForm: FC<isLoadingType> = ({ loading }) => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(syrupBaseIngredientReducer, initialState);
  const { activeTab } = state;

  const tabMappedData = useMemo(() => {
    return SYRUP_BASE_INGREDIENT_TABS;
  }, []);

  const tabHandler = (_: SyntheticEvent, activeTab: SyrupBaseIngredientTabsEnum) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab });
  };

  return (
    <Fragment>
      <Tabs value={activeTab} onChange={tabHandler} textColor="inherit" variant="scrollable">
        {tabMappedData?.map((item) => {
          const { name, value } = item;

          return <Tab value={value} key={value} label={name} id={value} iconPosition="start" />;
        })}
      </Tabs>

      <Box pt={5}>
        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.GENERAL}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <General loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.NUTRIENTS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <Nutrients loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel activeTab={activeTab} value={SyrupBaseIngredientTabsEnum.HEAVY_METALS}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={8}>
              <HeavyMetals loading={loading} />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default SyrupBaseIngredientForm;
