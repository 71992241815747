import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { Box, Card, CardContent, CardHeader, Grid } from '@mui/material';
import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
// components
import Selector from 'components/common/Selector';
import DataLoader from 'components/common/DataLoader';
import LineChart from '../components/Charts/LineChart';
import NoDataFound from 'components/common/NoDataFound';
import IngredientSelect from 'components/common/simpleSelectors/Ingredient';
import ProductTypeSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
// constants, helper, interfaces
import {
  YEAR_TEXT,
  HTTP_STATUS,
  INGREDIENT_TEXT,
  DOSAGE_TYPE_TEXT,
  DOSAGE_FORM_TEXT,
  TREND_ANALYSIS_TEXT,
  selectTypeInitialValues,
  PLEASE_SELECT_INGREDIENT,
} from 'constants/index';
import { getLastFiveYears } from 'lib/helper';
import { SelectType, TrendAnalysisProps } from 'interfaces';
import { ActionType } from 'reducer/ingredientReportReducer';
import { useFindFormulasCountByYearLazyQuery } from 'generated/graphql';

const TrendAnalysis = forwardRef<ChartJSOrUndefined<'line'>, TrendAnalysisProps>(
  ({ year, dispatch, ingredients, supplierId, productType, subProductType, organizationId }, ref) => {
    const [data, setData] = useState<number[]>([]);

    const { value: productTypeId } = productType || {};
    const { value: subProductTypeId } = subProductType || {};

    const [findFormulasCountByYear, { loading }] = useFindFormulasCountByYearLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { findFormulasCountByYear } = data;
        const { response, data: formulas } = findFormulasCountByYear || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          setData(formulas);
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

    const resetPage = () => {
      setData([]);
    };

    const handleProductTypeChange = (value: SelectType) => {
      dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: value });
      dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: selectTypeInitialValues });
    };

    const handleSubProductTypeChange = (value: SelectType) => {
      dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: value });
    };

    const handleIngredientChange = (value: SelectType[]) => {
      dispatch({ type: ActionType.SET_INGREDIENT, ingredients: value });
    };

    const yearHandler = (val: string) => {
      dispatch({ type: ActionType.SET_YEAR, year: val });
    };

    const fetchFormulasCount = useCallback(async () => {
      if (ingredients?.length) {
        await findFormulasCountByYear({
          variables: {
            findFormulasCountByYearInput: {
              ingredients: ingredients.map(({ value }) => value),
              ...(year && { year: Number(year) }),
              ...(productTypeId && { productTypeId }),
              ...(organizationId && { organizationId }),
              ...(subProductTypeId && { subProductTypeId }),
            },
          },
        });
      } else {
        setData([]);
      }
    }, [year, ingredients, productTypeId, organizationId, subProductTypeId, findFormulasCountByYear]);

    useEffect(() => {
      fetchFormulasCount();
    }, [fetchFormulasCount]);

    return (
      <div>
        <Card>
          <CardHeader title={TREND_ANALYSIS_TEXT} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IngredientSelect
                  isClearable
                  title={INGREDIENT_TEXT}
                  supplierId={supplierId}
                  name={'ingredient'}
                  value={ingredients}
                  handleChange={handleIngredientChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ProductTypeSelect
                  isActive
                  isClearable
                  name={'dosageForm'}
                  value={productType}
                  title={DOSAGE_FORM_TEXT}
                  handleChange={handleProductTypeChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <SubProductTypeSelect
                  isActive
                  isClearable
                  name={'subType'}
                  title={DOSAGE_TYPE_TEXT}
                  value={subProductType}
                  productTypeId={productType?.value}
                  handleChange={handleSubProductTypeChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Selector
                  value={year}
                  title={YEAR_TEXT}
                  id="user-graph-selector"
                  options={getLastFiveYears()}
                  onChange={yearHandler}
                />
              </Grid>
            </Grid>
            {ingredients?.length ? (
              <Box mt={2}>
                {loading ? (
                  <DataLoader columns={12} rows={1} height={300} />
                ) : (
                  <LineChart id="trend-analysis" data={data} ref={ref} />
                )}
              </Box>
            ) : (
              <NoDataFound noData description={PLEASE_SELECT_INGREDIENT} />
            )}
          </CardContent>
        </Card>
      </div>
    );
  },
);

export default memo(TrendAnalysis);
