import { FC, Fragment, useContext, useState } from 'react';
import { Box, Collapse, IconButton, Typography, Grid } from '@mui/material';
import { KeyboardArrowDown as ArrowDownIcon, KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material';
// components
import RowView from 'components/common/RowView';
import DataLoader from 'components/common/DataLoader';
// context, helper, constants
import { formatToLowerCase, formatValue } from 'lib/helper';
import { FormulaDetailContext } from 'contexts/FormulaDetailContext';
import {
  COST_TEXT,
  WEIGHT_TEXT,
  POTENCY_TEXT,
  TYPE_TEXT,
  VALUE_TEXT,
  COST_PERCENTAGE_TEXT,
} from 'constants/index';

const FormulaIngredients: FC = () => {
  const { state, loading } = useContext(FormulaDetailContext);
  const { formula } = state;
  const { formulaIngredients } = formula || {};

  const [itemOpenState, setItemOpenState] = useState(Array(formulaIngredients?.length));

  const handleItemClick = (index: number) => {
    const newOpenState = [...itemOpenState];
    newOpenState[index] = !newOpenState[index];
    setItemOpenState(newOpenState);
  };

  return (
    <Fragment>
      {loading ? (
        <DataLoader columns={12} rows={2} />
      ) : (
        formulaIngredients?.map((item, index) => {
          const { ingredient, potency, value, unit, type, mgWeight, totalCost, ingCostContribution } =
            item || {};
          const { id, name } = ingredient || {};
          const potencyInPercentage = potency ? potency * 100 : 0;
          return (
            <Fragment key={id}>
              <Box
                flex={1}
                paddingX={2}
                paddingY={1}
                mt={1.5}
                display="flex"
                borderRadius={1}
                alignItems="center"
                bgcolor={'primary.main'}
                onClick={() => handleItemClick(index)}>
                <Typography variant="caption" fontWeight={700} flex={1}>
                  {name}
                </Typography>

                <IconButton aria-label="expand row" size="small">
                  {itemOpenState[index] ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </IconButton>
              </Box>

              <Collapse in={itemOpenState[index]} timeout="auto" unmountOnExit>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={VALUE_TEXT}
                        value={`${value ?? '--'} ${unit ? formatToLowerCase(unit) : ''}`}
                      />
                    </Grid>

                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={POTENCY_TEXT}
                        value={`${potencyInPercentage}`}
                      />
                    </Grid>
                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={TYPE_TEXT}
                        value={type ? formatValue(type) : '--'}
                      />
                    </Grid>

                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={WEIGHT_TEXT}
                        value={`${mgWeight || '--'} mg`}
                      />
                    </Grid>

                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={COST_TEXT}
                        value={totalCost ? `$ ${Number(totalCost)}` : '0'}
                      />
                    </Grid>
                    <Grid item xs={12} xl={4}>
                      <RowView
                        mt={0}
                        flexDirection="column"
                        name={COST_PERCENTAGE_TEXT}
                        value={ingCostContribution ? `${Number(ingCostContribution)} %` : '0'}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </Fragment>
          );
        })
      )}
    </Fragment>
  );
};

export default FormulaIngredients;
