// imports
import { FC, useCallback, useMemo, useState } from 'react';
// components
import InfiniteSelect from './InfiniteSelect';
// interfaces, constants, helper
import { HTTP_STATUS } from 'constants/index';
import {
  ProductFormulationSyrupTypesPayload,
  useFindAllProductFormulationSyrupTypesSelectorLazyQuery,
} from 'generated/graphql';
import { renderProductFormulationSyrupType } from 'lib/helper';
import { ProductFormulationSyrupTypeSelectProps } from 'interfaces';

const ProductFormulationSyrupTypeSelect: FC<ProductFormulationSyrupTypeSelectProps> = ({
  name,
  title,
  value,
  isActive,
  handleChange,
  ...rest
}) => {
  const [totalPages, setTotalPages] = useState<number>(0);
  const [getLoading, setGetLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<ProductFormulationSyrupTypesPayload['data']>([]);

  const updatedOptions = useMemo(() => {
    if (options?.length) {
      return renderProductFormulationSyrupType(options);
    }
    return [];
  }, [options]);

  const [findAllProductFormulationSyrupTypes, { loading }] =
    useFindAllProductFormulationSyrupTypesSelectorLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { findAllProductFormulationSyrupTypes } = data;
        const { data: bottleSizes, response, pagination } = findAllProductFormulationSyrupTypes || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          const { totalPages: pages } = pagination || {};

          setTotalPages(pages);
          setOptions([
            ...(getLoading ? [] : options || []),
            ...((bottleSizes as ProductFormulationSyrupTypesPayload['data']) ?? []),
          ]);
          setGetLoading(false);
        }
      },
      onError: () => {
        setOptions([]);
        setGetLoading(false);
      },
    });

  const fetchData = useCallback(
    async (page?: number, query?: string) => {
      if (!page) setGetLoading(true);
      await findAllProductFormulationSyrupTypes({
        variables: {
          findAllProductFormulationSyrupTypesInput: {
            ...(query && { search: query }),
            paginationOptions: { page: page || 1, limit: 10 },
            ...(typeof isActive === 'boolean' && { isActive }),
          },
        },
      });
    },
    [findAllProductFormulationSyrupTypes, isActive],
  );

  return (
    <InfiniteSelect
      name={name}
      title={title}
      value={value}
      loading={loading}
      fetchData={fetchData}
      setOptions={setOptions}
      totalPages={totalPages}
      getLoading={getLoading}
      options={updatedOptions}
      handleChange={handleChange}
      {...rest}
    />
  );
};

export default ProductFormulationSyrupTypeSelect;
