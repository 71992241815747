import { FC } from 'react';
import { Box, Grid } from '@mui/material';
// components
import TableSearchField from 'components/common/TableSearchField';
// constants, reducer, interfaces
import { ActionType } from 'reducer/SyrupBaseIngredient';
import { SyrupBaseIngredientsTableFilterProps } from 'interfaces';
import { PRODUCT_FORMULATION_SYRUP_TYPE_TEXT } from 'constants/index';
import ProductFormulationSyrupTypeSelect from 'components/common/simpleSelectors/FormulationSyrupType';

const SyrupBaseIngredientsTableFilter: FC<SyrupBaseIngredientsTableFilterProps> = ({ dispatch, state }) => {
  const { search, productFormulationSyrupType } = state || {};

  const onSearch = (val: string) => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: val });
  };

  const onClear = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_SEARCH, search: '' });
  };

  return (
    <Box sx={{ px: { xs: 2, lg: 3 }, py: { xs: 2, lg: 3 } }}>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <TableSearchField search={search} withSpacing={false} onChange={onSearch} onClear={onClear} />
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <ProductFormulationSyrupTypeSelect
            isClearable
            value={productFormulationSyrupType}
            name="product-Formulation-Syrup-Type"
            title={PRODUCT_FORMULATION_SYRUP_TYPE_TEXT}
            handleChange={(item) =>
              dispatch({
                type: ActionType.SET_PRODUCT_FORMULATION_SYRUP_TYPE,
                productFormulationSyrupType: item,
              })
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SyrupBaseIngredientsTableFilter;
