import { Box, Button, Grid, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';
import { FC, Reducer, useCallback, useEffect, useReducer, useRef } from 'react';
// components
import TrendAnalysis from './TrendAnalysis';
import IngredientPdf from './IngredientPdf';
import UsageGraphCard from './UsageGraphCard';
import PdfViewer from '../components/Pdf/PdfViewer';
import FormulaIngredientTable from './FormulaIngredientTable';
import OrgUserSelect from 'components/common/simpleSelectors/OrgUser';
import SupplierSelect from 'components/common/simpleSelectors/Supplier';
// constants, styles
import { SelectType } from 'interfaces';
import { formatMomentStamp } from 'lib/helper';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { FormulasIngredientPayload, useGetFormulasIngredientReportLazyQuery } from 'generated/graphql';
import {
  ALL_TEXT,
  HTTP_STATUS,
  ALL_TIME_TEXT,
  SUPPLIER_TEXT,
  ORG_OR_ADMIN_TEXT,
  DOWNLOAD_REPORT_TEXT,
  INGREDIENTS_REPORT_TEXT,
} from 'constants/index';
import {
  State,
  Action,
  ActionType,
  initialState,
  ingredientReportReducer,
} from 'reducer/ingredientReportReducer';

const Ingredients: FC = () => {
  const pieRef = useRef<ChartJSOrUndefined<'pie'>>(null);
  const lineRef = useRef<ChartJSOrUndefined<'line'>>(null);

  const [state, dispatch] = useReducer<Reducer<State, Action>>(ingredientReportReducer, initialState);

  const {
    top,
    open,
    data,
    year,
    toDate,
    supplier,
    fromDate,
    ingredients,
    dosageType,
    productType,
    organization,
    subProductType,
    ingredientUsage,
    formulationType,
    lineCanvas,
    pieCanvas,
    labels,
  } = state;

  const { value: supplierId, name: supplierName } = supplier || {};
  const { value: dosageTypeId, name: dosageTypeName } = dosageType || {};
  const { value: organizationId, name: organizationName } = organization || {};
  const { value: formulationTypeId, name: formulationName } = formulationType || {};
  const { name: productTypeName } = productType || {};
  const { name: subProductTypeName } = subProductType || {};

  const [getFormulasIngredientReport, { loading }] = useGetFormulasIngredientReportLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { getFormulasIngredient } = data;
      const { response, data: formulas } = getFormulasIngredient || {};
      const { status } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        dispatch({ type: ActionType.SET_DATA, data: formulas as FormulasIngredientPayload['data'] });
      } else {
        resetPage();
      }
    },

    onError: () => {
      resetPage();
    },
  });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const handleOpen = () => {
    const pieCanvasPng = pieRef.current?.toBase64Image('image/png');
    const lineCanvasPng = lineRef.current?.toBase64Image('image/png');
    dispatch({ type: ActionType.SET_PIE_CANVAS, pieCanvas: pieCanvasPng || '' });
    dispatch({ type: ActionType.SET_LINE_CANVAS, lineCanvas: lineCanvasPng || '' });
    dispatch({ type: ActionType.SET_OPEN, open: true });
  };

  const handleClose = () => {
    dispatch({ type: ActionType.SET_OPEN, open: false });
  };

  const handleChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_ORGANIZATION, organization: value });
  };

  const handleSupplierChange = (value: SelectType) => {
    dispatch({ type: ActionType.SET_SUPPLIER, supplier: value });
  };

  const fetchFormulasIngredientReport = useCallback(async () => {
    await getFormulasIngredientReport({
      variables: {
        getFormulasIngredientInput: {
          top: Number(top),
          ...(toDate && { toDate }),
          ...(fromDate && { fromDate }),
          ...(supplierId && { supplierId }),
          ...(organizationId && { organizationId }),
          ...(dosageTypeId && { productTypeId: dosageTypeId }),
          ...(formulationTypeId && { subProductTypeId: formulationTypeId }),
        },
      },
    });
  }, [
    top,
    toDate,
    fromDate,
    supplierId,
    dosageTypeId,
    organizationId,
    formulationTypeId,
    getFormulasIngredientReport,
  ]);

  useEffect(() => {
    fetchFormulasIngredientReport();
  }, [fetchFormulasIngredientReport]);

  return (
    <>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{INGREDIENTS_REPORT_TEXT}</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          <FileDownloadIcon />
          {DOWNLOAD_REPORT_TEXT}
        </Button>
      </Box>

      {/* filters */}
      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <OrgUserSelect
              isClearable
              name="org-admin"
              value={organization}
              title={ORG_OR_ADMIN_TEXT}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <SupplierSelect
              isClearable
              name="supplier"
              value={supplier}
              title={SUPPLIER_TEXT}
              handleChange={handleSupplierChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <FormulaIngredientTable
            top={top}
            data={data}
            loading={loading}
            toDate={toDate}
            dispatch={dispatch}
            fromDate={fromDate}
            dosageType={dosageType}
            formulationType={formulationType}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TrendAnalysis
            year={year}
            ref={lineRef}
            dispatch={dispatch}
            ingredients={ingredients}
            supplierId={supplierId}
            productType={productType}
            subProductType={subProductType}
            organizationId={organizationId}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <UsageGraphCard
            ref={pieRef}
            dispatch={dispatch}
            supplierId={supplierId}
            ingredients={ingredientUsage}
            organizationId={organizationId}
          />
        </Grid>
      </Grid>

      <PdfViewer
        open={open}
        handleClose={handleClose}
        title={INGREDIENTS_REPORT_TEXT}
        document={
          open ? (
            <IngredientPdf
              top={top}
              data={data}
              year={year}
              labels={labels}
              pieCanvas={pieCanvas}
              lineCanvas={lineCanvas}
              supplierName={supplierName || ALL_TEXT}
              ingredientName={ingredients?.map(({ name }) => name).join(', ') || ALL_TEXT}
              dosageTypeName={dosageTypeName || ALL_TEXT}
              formulationName={formulationName || ALL_TEXT}
              productTypeName={productTypeName || ALL_TEXT}
              organizationName={organizationName || ALL_TEXT}
              ingredientUsage={ingredientUsage?.map(({ name }) => name).join(', ') || ALL_TEXT}
              subProductTypeName={subProductTypeName || ALL_TEXT}
              dateRange={
                fromDate
                  ? `${formatMomentStamp(fromDate)} - ${
                      toDate ? formatMomentStamp(toDate) : formatMomentStamp()
                    }`
                  : ALL_TIME_TEXT
              }
            />
          ) : (
            <></>
          )
        }
      />
    </>
  );
};

export default Ingredients;
