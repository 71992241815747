import { FC } from 'react';
import { Grid } from '@mui/material';
// components
import AddressCard from 'components/main/AddressCard';
import UserCard from 'components/main/Users/Add/UserCard';
// interfaces
import { UserFormProps } from 'interfaces';

const UserForm: FC<UserFormProps> = ({ loading }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <UserCard loading={loading} />
      </Grid>
      <Grid item xs={12} xl={6}>
        <AddressCard loading={loading} />
      </Grid>
    </Grid>
  );
};

export default UserForm;
