// imports
import { FC } from 'react';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
// components
import SidebarMain from 'components/common/SidebarMain';
import SidebarSubList from 'components/common/SubSidebar';

import { ExcipientBottleIcon, ExcipientIcon, ProductsIcon, SweetenerIcon, SyrupIcon } from 'assets/svgs';
// constants
import {
  BOTTLE_TEXT,
  PRODUCT_TEXT,
  EXCIPIENT_TEXT,
  EXCIPIENT_ROUTE,
  SYRUP_BASE_TEXT,
  BOTTLE_SIZE_ROUTE,
  PRODUCT_TYPE_ROUTE,
  SWEETENER_SYSTEM_TEXT,
  SUPER_ADMIN_BOTTLE_LIST,
  SUPER_ADMIN_PRODUCT_LIST,
  PRODUCT_INGREDIENT_ROUTE,
  SWEETENER_COMPONENT_ROUTE,
  SUPER_ADMIN_EXCIPIENT_LIST,
  SUPER_ADMIN_MANAGEMENT_LIST,
  SUPER_ADMIN_SWEETENER_LIST,
  SUPER_ADMIN_SYRUP_BASE_LIST,
  SUPER_ADMIN_SAND_COATING_LIST,
  SAND_COATING_TEXT,
  SAND_COATING_ROUTE,
} from 'constants/index';

const ManagementList: FC = () => {
  return (
    <>
      <SidebarMain list={SUPER_ADMIN_MANAGEMENT_LIST} />

      <SidebarSubList
        defaultRoute={PRODUCT_TYPE_ROUTE}
        mainTitle={PRODUCT_TEXT}
        list={SUPER_ADMIN_PRODUCT_LIST}
        icon={ProductsIcon}
      />
      <SidebarSubList
        defaultRoute={EXCIPIENT_ROUTE}
        mainTitle={EXCIPIENT_TEXT}
        list={SUPER_ADMIN_EXCIPIENT_LIST}
        icon={ExcipientIcon}
      />

      <SidebarSubList
        defaultRoute={BOTTLE_SIZE_ROUTE}
        mainTitle={BOTTLE_TEXT}
        list={SUPER_ADMIN_BOTTLE_LIST}
        icon={ExcipientBottleIcon}
      />

      <SidebarSubList
        defaultRoute={SWEETENER_COMPONENT_ROUTE}
        mainTitle={SWEETENER_SYSTEM_TEXT}
        list={SUPER_ADMIN_SWEETENER_LIST}
        icon={SweetenerIcon}
      />

      <SidebarSubList
        icon={SyrupIcon}
        mainTitle={SYRUP_BASE_TEXT}
        list={SUPER_ADMIN_SYRUP_BASE_LIST}
        defaultRoute={PRODUCT_INGREDIENT_ROUTE}
      />
      <SidebarSubList
        icon={<HourglassBottomIcon />}
        mainTitle={SAND_COATING_TEXT}
        list={SUPER_ADMIN_SAND_COATING_LIST}
        defaultRoute={SAND_COATING_ROUTE}
      />
    </>
  );
};

export default ManagementList;
