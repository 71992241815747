import { useNavigate } from 'react-router-dom';
import { Box, Chip, Grid, TableCell, TableRow } from '@mui/material';
import { FC, Fragment, Reducer, useCallback, useEffect, useReducer } from 'react';
// components
import { Alert } from 'components/common/Alert';
import TableLoader from 'components/common/TableLoader';
import NoDataFound from 'components/common/NoDataFound';
import TableContainer from 'components/common/TableContainer';
import TableComponent from 'components/common/TableComponent';
import ActionMenuDropdown from 'components/common/ActionMenuDropdown';
import TableTabsComponent from 'components/common/TableTabsComponent';
import ProductSizeSelect from 'components/common/simpleSelectors/ProductSize';
import ProductTypesSelect from 'components/common/simpleSelectors/ProductType';
import SubProductTypeSelect from 'components/common/simpleSelectors/SubProductType';
import ProductFormulationTypes from 'components/common/simpleSelectors/ProductFormulationType';
// constants, reducers, graphql, styles , interfaces and helper
import { SelectType } from 'interfaces';
import {
  TABS,
  TabEnum,
  HTTP_STATUS,
  ACTIVE_TEXT,
  INACTIVE_TEXT,
  PRODUCT_TYPE_TEXT,
  PRODUCT_SIZE_TEXT,
  LOADING_TABLE_ROWS,
  SUB_PRODUCT_TYPE_TEXT,
  PRODUCT_FORMULATION_TEXT,
  FORMULATION_SYRUP_TYPE_TABLE_HEADER,
  EDIT_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE,
  VIEW_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE,
} from 'constants/index';
import {
  ProductFormulationSyrupTypesPayload,
  useUpdateProductFormulationSyrupTypeMutation,
  useFindAllProductFormulationSyrupTypesTableLazyQuery,
} from 'generated/graphql';
import {
  State,
  Action,
  ActionType,
  initialState,
  formulationSyrupTypeReducer,
} from 'reducer/formulationSyrupType';
import { cursorPointer } from 'styles/commonComponentStyle';

const FormulationSyrupTypeTable: FC = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer<Reducer<State, Action>>(formulationSyrupTypeReducer, initialState);
  const {
    page,
    rowsPerPage,
    count,
    data,
    activeTab,
    productFormulation,
    productSize,
    productType,
    subProductType,
  } = state;

  const { value: productTypeId } = productType;
  const { value: subProductTypeId } = subProductType;
  const { value: productFormulationTypeId } = productFormulation;
  const { value: productSizeId } = productSize;

  const [findAllProductFormulationSyrupTypes, { loading: fetchLoading, error }] =
    useFindAllProductFormulationSyrupTypesTableLazyQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { findAllProductFormulationSyrupTypes } = data;
        const {
          response,
          data: productFormulationSyrupTypes,
          pagination,
        } = findAllProductFormulationSyrupTypes || {};
        const { status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          const { page, totalCount } = pagination || {};
          dispatch({ type: ActionType.SET_PAGE, page: page || 1 });
          dispatch({ type: ActionType.SET_COUNT, count: totalCount || 0 });
          dispatch({
            type: ActionType.SET_DATA,
            data: productFormulationSyrupTypes as ProductFormulationSyrupTypesPayload['data'],
          });
        } else {
          resetPage();
        }
      },

      onError: () => {
        resetPage();
      },
    });

  const [updateProductFormulationSyrupTypeStatus, { loading: updateLoading }] =
    useUpdateProductFormulationSyrupTypeMutation({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { updateProductFormulationSyrupType } = data;
        const { response } = updateProductFormulationSyrupType || {};
        const { message, status } = response || {};
        if (status === HTTP_STATUS.SUCCESS) {
          Alert.success(message || '');
          fetchAllProductFormulationSyrupTypes();
        } else {
          Alert.error(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const resetPage = () => {
    dispatch({ type: ActionType.SET_PAGE, page: 1 });
    dispatch({ type: ActionType.SET_COUNT, count: 0 });
    dispatch({ type: ActionType.SET_DATA, data: [] });
  };

  const fetchAllProductFormulationSyrupTypes = useCallback(async () => {
    await findAllProductFormulationSyrupTypes({
      variables: {
        findAllProductFormulationSyrupTypesInput: {
          paginationOptions: {
            limit: rowsPerPage,
            page,
          },
          ...(productTypeId && { productTypeId }),
          ...(productSizeId && { productSizeId }),
          ...(subProductTypeId && { subProductTypeId }),
          ...(productFormulationTypeId && { productFormulationTypeId }),
          ...(activeTab !== TabEnum.All && { isActive: activeTab === TabEnum.Active }),
        },
      },
    });
  }, [
    page,
    activeTab,
    rowsPerPage,
    productTypeId,
    productSizeId,
    subProductTypeId,
    productFormulationTypeId,
    findAllProductFormulationSyrupTypes,
  ]);

  useEffect(() => {
    fetchAllProductFormulationSyrupTypes();
  }, [fetchAllProductFormulationSyrupTypes]);

  const tabHandler = (_: React.SyntheticEvent<Element, Event>, value: string) => {
    dispatch({ type: ActionType.SET_ACTIVE_TAB, activeTab: value as TabEnum });
  };

  const onActiveHandler = async (id: string, status: boolean) => {
    await updateProductFormulationSyrupTypeStatus({
      variables: {
        updateProductFormulationSyrupTypeInput: {
          id,
          isActive: !status,
        },
      },
    });
  };

  const loading = fetchLoading || updateLoading;

  const noData = Boolean((!fetchLoading && data?.length === 0) || error);

  const onProductTypeChange = useCallback((item: SelectType) => {
    dispatch({ type: ActionType.SET_PRODUCT_TYPE, productType: item });
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: initialState.subProductType });

    dispatch({
      type: ActionType.SET_PRODUCT_FORMULATION,
      productFormulation: initialState.productFormulation,
    });

    dispatch({
      type: ActionType.SET_PRODUCT_SIZE,
      productSize: initialState.productSize,
    });
  }, []);

  const onSubProductTypeChange = useCallback((item: SelectType) => {
    dispatch({ type: ActionType.SET_SUB_PRODUCT_TYPE, subProductType: item });
    dispatch({
      type: ActionType.SET_PRODUCT_FORMULATION,
      productFormulation: initialState.productFormulation,
    });
    dispatch({
      type: ActionType.SET_PRODUCT_SIZE,
      productSize: initialState.productSize,
    });
  }, []);

  return (
    <TableContainer>
      <TableTabsComponent activeTab={activeTab} tabsList={TABS} tabHandler={tabHandler} />

      <Box m={({ spacing }) => spacing(2, 3)}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductTypesSelect
              isClearable
              value={productType}
              name="product-type"
              title={PRODUCT_TYPE_TEXT}
              handleChange={onProductTypeChange}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SubProductTypeSelect
              isClearable
              value={subProductType}
              name="sub-product-type"
              title={SUB_PRODUCT_TYPE_TEXT}
              productTypeId={productTypeId}
              handleChange={onSubProductTypeChange}
            />
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductFormulationTypes
              isClearable
              value={productFormulation}
              name="product-formulation"
              productSubTypeId={subProductTypeId}
              title={PRODUCT_FORMULATION_TEXT}
              productTypeId={productTypeId}
              handleChange={(item) =>
                dispatch({ type: ActionType.SET_PRODUCT_FORMULATION, productFormulation: item })
              }
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <ProductSizeSelect
              isClearable
              value={productSize}
              name="product-size"
              title={PRODUCT_SIZE_TEXT}
              productTypeId={productTypeId}
              subProductTypeId={subProductTypeId}
              handleChange={(item) => dispatch({ type: ActionType.SET_PRODUCT_SIZE, productSize: item })}
            />
          </Grid>
        </Grid>
      </Box>

      <TableComponent
        noData={noData}
        page={page - 1}
        count={count}
        rowsPerPage={rowsPerPage}
        tableHeader={FORMULATION_SYRUP_TYPE_TABLE_HEADER}
        setPage={(p: number) => dispatch({ type: ActionType.SET_PAGE, page: p + 1 })}
        setRowsPerPage={(r: number) => dispatch({ type: ActionType.SET_ROWS_PER_PAGE, rowsPerPage: r })}>
        {loading ? (
          <TableLoader columns={8} rows={LOADING_TABLE_ROWS} />
        ) : (
          <Fragment>
            {data?.map((cell) => {
              const {
                id,
                name,
                cost,
                weight,
                isActive,
                productType,
                productSize,
                subProductType,
                productFormulationType,
              } = cell || {};

              const { name: productTypeName } = productType || {};
              const { name: productSizeName } = productSize || {};
              const { name: subProductTypeName } = subProductType || {};
              const { name: productFormulationTypeName } = productFormulationType || {};

              return (
                <TableRow key={id}>
                  <TableCell
                    sx={cursorPointer}
                    onClick={() => navigate(`${VIEW_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}/${id}`)}>
                    <b>{name ?? '--'}</b>
                  </TableCell>
                  <TableCell>{weight ? `${weight}` : '--'}</TableCell>
                  <TableCell>{cost ? `$${cost}` : '--'}</TableCell>

                  <TableCell>{productTypeName ?? '--'}</TableCell>
                  <TableCell>{subProductTypeName ?? '--'}</TableCell>
                  <TableCell>{productFormulationTypeName ?? '--'}</TableCell>
                  <TableCell>{productSizeName ?? '--'}</TableCell>

                  <TableCell>
                    <Chip
                      size="small"
                      variant="outlined"
                      label={isActive ? ACTIVE_TEXT : INACTIVE_TEXT}
                      color={isActive ? 'success' : 'error'}
                    />
                  </TableCell>
                  <TableCell>
                    <ActionMenuDropdown
                      id={id ?? ''}
                      isActive={!!isActive}
                      editRoute={EDIT_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}
                      onActiveHandler={onActiveHandler}
                      viewRoute={VIEW_PRODUCT_FORMULATION_SYRUP_TYPE_ROUTE}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </Fragment>
        )}
      </TableComponent>

      <NoDataFound noData={noData} />
    </TableContainer>
  );
};

export default FormulationSyrupTypeTable;
