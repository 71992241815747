import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import AddressCard from 'components/main/AddressCard';
import UserCard from 'components/main/Users/Add/UserCard';
import UserPasswordCard from 'components/main/Users/Edit/UserPasswordCard';
// interfaces, constants, validation Schema, graphql
import { addressSchema, editUserSchema, updatePasswordSchema } from 'validationSchema';
import {
  AddressType,
  AddressTitle,
  useUpdatePasswordMutation,
  useUpdateOrgAndUserMutation,
  useUpdateAddressByTypeMutation,
} from 'generated/graphql';
import {
  EditUserFormType,
  EditUserFormProps,
  UpdatePasswordFormType,
  EditUserAddressFormType,
} from 'interfaces';
import {
  HTTP_STATUS,
  addressInitialValues,
  editUserInitialValues,
  updatePasswordInitialValues,
} from 'constants/index';

const EditUserForm: FC<EditUserFormProps> = ({ loading: getLoading, id, user }) => {
  const navigate = useNavigate();
  const { organization } = user || {};
  const { id: orgId } = organization || {};

  const addressMethods = useForm<EditUserAddressFormType>({
    defaultValues: addressInitialValues,
    resolver: yupResolver(addressSchema),
  });

  const methods = useForm<EditUserFormType>({
    defaultValues: editUserInitialValues,
    resolver: yupResolver(editUserSchema),
  });

  const passwordMethods = useForm<UpdatePasswordFormType>({
    defaultValues: updatePasswordInitialValues,
    resolver: yupResolver(updatePasswordSchema),
  });

  const { handleSubmit, setValue } = methods;
  const { handleSubmit: addressSubmit, setValue: setAddressValue } = addressMethods;

  const { handleSubmit: passwordSubmit } = passwordMethods;

  const [updateOrgAndUser, { loading: updateUserLoading }] = useUpdateOrgAndUserMutation({
    onCompleted: (data) => {
      const { updateOrgAndUser } = data;
      const { response } = updateOrgAndUser || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
        navigate(-1);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updatePassword, { loading: updatePasswordLoading }] = useUpdatePasswordMutation({
    onCompleted: (data) => {
      const { updatePassword } = data;
      const { response } = updatePassword || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateAddress, { loading: updateAddressLoading }] = useUpdateAddressByTypeMutation({
    onCompleted: (data) => {
      const { updateAddressByType } = data;
      const { response } = updateAddressByType || {};
      const { status, message } = response || {};
      if (message && status === HTTP_STATUS.SUCCESS) {
        Alert.success(message);
      } else {
        Alert.warning(message ?? '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<EditUserFormType> = async (data) => {
    const { email, firstName, lastName, organizationName, phone, maxUser } = data;
    if (id) {
      await updateOrgAndUser({
        variables: {
          updateOrgAndUserInput: {
            organization: {
              id: orgId ?? '',
              name: organizationName ?? '',
              maxUser: Number(maxUser),
            },
            user: {
              id: id ?? '',
              email: email ?? '',
              firstName: firstName ?? '',
              lastName: lastName ?? '',
              ...(phone && { phone: phone }),
            },
          },
        },
      });
    }
  };

  const onPasswordSubmit: SubmitHandler<UpdatePasswordFormType> = async (data) => {
    const { password } = data;
    if (id) {
      await updatePassword({
        variables: {
          updatePasswordInput: {
            id: id ?? '',
            password: password ?? '',
          },
        },
      });
    }
  };

  const onAddressSubmit: SubmitHandler<EditUserAddressFormType> = async (data) => {
    const { address1, address2, city, zip, state, country } = data;
    const { value: stateValue } = state || {};
    const { name: countryName, value: countryCode } = country || {};

    if (orgId) {
      await updateAddress({
        variables: {
          input: {
            address1,
            address2,
            city,
            zip,
            state: stateValue,
            country: countryName,
            countryCode,
            typeId: orgId,
            type: AddressType.Organization,
            title: AddressTitle.OrganizationAddress,
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const { firstName, lastName, email, phone, organization } = user || {};
    const { name, maxUser, address } = organization || {};
    const { address1, address2, city, zip, state, country, countryCode } = address || {};

    setValue('email', email ?? '');
    setValue('firstName', firstName ?? '');
    setValue('lastName', lastName ?? '');
    setValue('organizationName', name ?? '');
    setValue('phone', phone ?? '');
    setValue('maxUser', `${maxUser}` ?? '');

    // set address values
    setAddressValue('address1', address1 ?? '');
    setAddressValue('address2', address2 ?? '');
    setAddressValue('city', city ?? '');
    setAddressValue('zip', zip ?? '');
    setAddressValue('state', {
      name: state ?? '',
      value: state ?? '',
    });
    setAddressValue('country', {
      name: country ?? '',
      value: countryCode ?? '',
    });
  }, [setValue, user, setAddressValue]);

  useEffect(() => {
    user && setValues();
  }, [setValues, user]);

  const loading = getLoading || updateUserLoading || updatePasswordLoading || updateAddressLoading;

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={6}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <UserCard loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
        <Grid item xs={12} xl={6}>
          <FormProvider {...addressMethods}>
            <form onSubmit={addressSubmit(onAddressSubmit)}>
              <AddressCard loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>

      <Grid container spacing={3} marginTop={1}>
        <Grid item xs={12} xl={6}>
          <FormProvider {...passwordMethods}>
            <form onSubmit={passwordSubmit(onPasswordSubmit)}>
              <UserPasswordCard loading={loading} isEdit />
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default EditUserForm;
