import { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Card, Grid } from '@mui/material';
// components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
// styles, constants, helpers, interfaces
import { flexCenter, flexStart } from 'theme/styleConstant';
import { generateRandomPassword } from 'lib/helper';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';
import { UpdatePasswordFormType, UpdateUserPasswordCardProps } from 'interfaces';
import { GENERATE_PASSWORD_TEXT, PASSWORD_TEXT, SAVE_TEXT } from 'constants/index';

const UserPasswordCard: FC<UpdateUserPasswordCardProps> = ({ loading, isEdit = false }) => {
  const { setValue } = useFormContext<UpdatePasswordFormType>();

  const generatePassword = () => {
    const password = generateRandomPassword();
    setValue('password', password);
  };

  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={1} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8} sx={flexCenter}>
            <InputController name="password" title={PASSWORD_TEXT} fieldType="password" isPassword />
          </Grid>

          <Grid item xs={12} lg={4} sx={flexStart}>
            <Button variant="contained" fullWidth sx={{ height: 55 }} onClick={generatePassword}>
              {GENERATE_PASSWORD_TEXT}
            </Button>
          </Grid>
        </Grid>
      )}
      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <Fragment></Fragment>
      )}
    </Card>
  );
};

export default UserPasswordCard;
