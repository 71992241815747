import { yupResolver } from '@hookform/resolvers/yup';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import IngredientBasicInfoCard from '../Add/components/General/IngredientBasicInfoCard';
// interfaces, constants, schema, graphql, helpers
import { EditSyrupBaseGeneralProps, SyrupBaseIngredientBasicInfoCardType } from 'interfaces';
import { HTTP_STATUS, SyrupBaseIngredientBasicInfoCardInitialValues } from 'constants/index';
import { syrupBaseIngredientBasicInfoCardSchema } from 'validationSchema';
import { IngredientType, useUpdateSyrupBaseIngredientMutation } from 'generated/graphql';

const EditGeneral: FC<EditSyrupBaseGeneralProps> = ({
  ingredient,
  setIsLoading,
  fetchIngredient,
  loading: getLoading,
}) => {
  const { id: ingredientId } = ingredient || {};

  const basicInfoMethods = useForm<SyrupBaseIngredientBasicInfoCardType>({
    defaultValues: SyrupBaseIngredientBasicInfoCardInitialValues,
    resolver: yupResolver(syrupBaseIngredientBasicInfoCardSchema),
  });

  const [updateSyrupBaseIngredient, { loading: updateIngredientLoading }] =
    useUpdateSyrupBaseIngredientMutation({
      onCompleted: (data) => {
        const { updateSyrupBaseIngredient } = data;
        const { response } = updateSyrupBaseIngredient || {};
        const { status, message } = response || {};
        if (message && status === HTTP_STATUS.SUCCESS) {
          Alert.success(message);
        } else {
          Alert.warning(message ?? '');
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    });

  const { handleSubmit: handleBasicSubmit, setValue: setBasicValue } = basicInfoMethods;

  const onBasicSubmit: SubmitHandler<SyrupBaseIngredientBasicInfoCardType> = async (data) => {
    const { name, type, productFormulationSyrupType } = data;
    const { value: productFormulationSyrupTypeId } = productFormulationSyrupType || {};

    if (ingredientId) {
      await updateSyrupBaseIngredient({
        variables: {
          updateSyrupBaseIngredientsInput: {
            id: ingredientId,
            productFormulationSyrupTypeId,
            name,
            type,
          },
        },
      });
    }
  };

  const setValues = useCallback(() => {
    const { name, type, productFormulationSyrupType } = ingredient || {};

    const { id: productFormulationSyrupTypeId, name: syrupName } = productFormulationSyrupType || {};
    // setting basic info card values
    setBasicValue('name', name ?? '');
    setBasicValue('type', (type as IngredientType[]) ?? []);

    setBasicValue('productFormulationSyrupType', {
      name: syrupName ?? '',
      value: productFormulationSyrupTypeId ?? '',
    });

    setIsLoading(false);
  }, [ingredient, setBasicValue, setIsLoading]);

  useEffect(() => {
    ingredient && setValues();
  }, [setValues, ingredient]);

  const loading = updateIngredientLoading;

  return (
    <Fragment>
      <FormProvider {...basicInfoMethods}>
        <form onSubmit={handleBasicSubmit(onBasicSubmit)}>
          <IngredientBasicInfoCard loading={loading} isEdit />
        </form>
      </FormProvider>
    </Fragment>
  );
};

export default EditGeneral;
