import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { ImageListType, ImageType } from 'react-images-uploading';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
// components
import ProfileCard from './ProfileCard';
import { Alert } from 'components/common/Alert';
import AddressCard from 'components/main/AddressCard';
import UploadImage from 'components/common/UploadImage';
// constants, schema, interfaces, validation, reducer
import { addressSchema, updateUserProfileSchema } from 'validationSchema';
import { AddressFormType, ProfileFormProps, UpdateUserProfileType } from 'interfaces';
import { addressInitialValues, HTTP_STATUS, UpdateUserProfileInitialValues } from 'constants/index';
import {
  AddressTitle,
  AddressType,
  AttachmentTitle,
  AttachmentType,
  useUpdateAddressByTypeMutation,
  useUpdateUserInfoMutation,
} from 'generated/graphql';
import { AuthContext } from 'contexts/AuthContext';
import { isAdminOrSuperAdmin } from 'lib/helper';

const ProfileForm: FC<ProfileFormProps> = ({ user, loading: getLoading }) => {
  const { userRoles } = useContext(AuthContext);
  const isAdminUser = isAdminOrSuperAdmin(userRoles);
  // local states
  const [images, setImages] = useState<ImageType[]>([]);
  const [thumbnailId, setThumbnailId] = useState<string>('');

  const methods = useForm<UpdateUserProfileType>({
    defaultValues: UpdateUserProfileInitialValues,
    resolver: yupResolver(updateUserProfileSchema),
  });

  const addressMethods = useForm<AddressFormType>({
    defaultValues: addressInitialValues,
    resolver: yupResolver(addressSchema),
  });

  const { handleSubmit, setValue } = methods;
  const { handleSubmit: handleAddressSubmit, setValue: setAddressValue } = addressMethods;

  const { organization } = user || {};
  const { id: organizationId } = organization || {};

  const [updateUser, { loading: updateUserInfoLoading }] = useUpdateUserInfoMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateUserInfo } = data;
      const { response } = updateUserInfo;

      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
      } else {
        Alert.warning(message || '');
      }
    },
    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const [updateAddress, { loading: updateAddressLoading }] = useUpdateAddressByTypeMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { updateAddressByType } = data;
      const { response } = updateAddressByType;

      const { status, message } = response || {};
      if (status === HTTP_STATUS.SUCCESS) {
        Alert.success(message || '');
      } else {
        Alert.warning(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<UpdateUserProfileType> = async (data) => {
    const { firstName, lastName, phone } = data;
    await updateUser({
      variables: {
        updateUserInfoInput: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
        },
      },
    });
  };
  const setFormValues = useCallback(() => {
    const { firstName, lastName, phone, email, organization } = user || {};
    const { name, address } = organization || {};
    const { address1, address2, city, zip, country, state, countryCode } = address || {};

    setValue('email', email ?? '');
    setValue('phone', phone ?? '');
    setValue('lastName', lastName ?? '');
    setValue('firstName', firstName ?? '');
    setValue('orgName', name ?? '');
    // address
    setAddressValue('address1', address1 || '');
    setAddressValue('address2', address2 || '');
    setAddressValue('city', city || '');
    setAddressValue('zip', zip || '');
    setAddressValue('country', { name: country || '', value: countryCode || '' });
    setAddressValue('state', { name: state || '', value: state || '' });
  }, [setValue, user, setAddressValue]);

  const onAddressSubmit: SubmitHandler<AddressFormType> = async (data) => {
    const { address1, address2, city, zip, country, state } = data;
    const { value: countryCode, name: countryName } = country || {};
    const { value: stateCode } = state || {};

    await updateAddress({
      variables: {
        input: {
          address1,
          address2,
          city,
          zip,
          country: countryName,
          countryCode: countryCode,
          state: stateCode,
          typeId: organizationId || '',
          type: AddressType.Organization,
          title: AddressTitle.OrganizationAddress,
        },
      },
    });
  };

  useEffect(() => {
    user && setFormValues();
  }, [setFormValues, user]);

  const loading = getLoading || updateUserInfoLoading || updateAddressLoading;

  const onImagesChange = (list: ImageListType, attachmentId?: string) => {
    attachmentId && setThumbnailId(attachmentId);
    setImages(list);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} xl={6}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ProfileCard loading={loading} />
          </form>
        </FormProvider>
      </Grid>
      {isAdminUser && (
        <>
          <Grid item xs={12} xl={6}>
            <FormProvider {...addressMethods}>
              <form onSubmit={handleAddressSubmit(onAddressSubmit)}>
                <AddressCard loading={loading} isEdit />
              </form>
            </FormProvider>
          </Grid>
          <Grid item xs={12} xl={6}>
            <UploadImage
              isEdit
              id={organizationId}
              imageType={AttachmentType.Organization}
              imageLogoTitle={AttachmentTitle.OrganizationThumbnail}
              images={images}
              attachmentId={thumbnailId}
              onImageChange={onImagesChange}
              onChangeAttachmentId={(str) => setThumbnailId(str)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProfileForm;
