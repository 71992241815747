import { FC, Fragment } from 'react';
// components
import IngredientBasicInfoCard from 'components/main/SyrupBaseIngredients/Add/components/General/IngredientBasicInfoCard';
// interfaces
import { isLoadingType } from 'interfaces';

const General: FC<isLoadingType> = ({ loading }) => {
  return (
    <Fragment>
      <IngredientBasicInfoCard loading={loading} />
    </Fragment>
  );
};

export default General;
