import { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
import SyrupBaseIngredientsForm from 'components/main/SyrupBaseIngredients/Add/SyrupBaseIngredientsForm';
// interfaces, schema, graphql, constants, helpers
import { AddNewSyrupBaseIngredientFormType } from 'interfaces';
import { addNewSyrupBaseIngredientSchema } from 'validationSchema';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { formatIngredientInfo } from 'lib/helper';
import { useCreateSyrupBaseIngredientMutation } from 'generated/graphql';
import {
  HTTP_STATUS,
  ADD_SYRUP_BASE_INGREDIENTS_TEXT,
  SYRUP_BASE_INGREDIENT_IS_CREATING_TEXT,
  addNewSyrupBaseIngredientInitialValues,
} from 'constants/index';

const AddSyrupBaseIngredient: FC = () => {
  const navigate = useNavigate();

  const methods = useForm<AddNewSyrupBaseIngredientFormType>({
    defaultValues: addNewSyrupBaseIngredientInitialValues,
    resolver: yupResolver(addNewSyrupBaseIngredientSchema),
  });

  const { handleSubmit } = methods;

  const [createIngredient, { loading: createIngredientLoading }] = useCreateSyrupBaseIngredientMutation({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const { createSyrupBaseIngredient } = data;
      const { response } = createSyrupBaseIngredient || {};
      const { status, message } = response || {};
      if (status === HTTP_STATUS.CREATED) {
        navigate(-1);
        Alert.success(message || '');
      }
    },

    onError: ({ message }) => {
      Alert.error(message);
    },
  });

  const onSubmit: SubmitHandler<AddNewSyrupBaseIngredientFormType> = async (data) => {
    const { type, name, productFormulationSyrupType } = data || {};
    const { value: productFormulationSyrupTypeId } = productFormulationSyrupType || {};

    const ingredientInfoObject = formatIngredientInfo(data);

    await createIngredient({
      variables: {
        createSyrupBaseIngredientsInput: {
          name,
          type,
          ingredientInfo: ingredientInfoObject,
          productFormulationSyrupTypeId,
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{ADD_SYRUP_BASE_INGREDIENTS_TEXT}</Typography>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              id="create-ingredient-btn"
              disabled={createIngredientLoading}>
              {ADD_SYRUP_BASE_INGREDIENTS_TEXT}
            </Button>
          </Box>
          <SyrupBaseIngredientsForm loading={createIngredientLoading} />
        </form>
      </FormProvider>
      <BackdropLoader open={createIngredientLoading} text={SYRUP_BASE_INGREDIENT_IS_CREATING_TEXT} />
    </Fragment>
  );
};

export default AddSyrupBaseIngredient;
