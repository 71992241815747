import { FC } from 'react';
import { Box, Button, Card, Grid } from '@mui/material';
//components
import DataLoader from 'components/common/DataLoader';
import InputController from 'controller/InputController';
import CheckboxGroupController from 'controller/CheckboxGroup';
import ProductFormulationSyrupTypeSelector from 'components/common/selectors/productFormulationSyrupTypes';
//constants, interfaces, helper, interface, styles
import {
  SAVE_TEXT,
  TYPE_TEXT,
  COMMON_NAME_TEXT,
  INGREDIENTS_TYPE_MAPPED,
  PRODUCT_FORMULATION_SYRUP_TYPE_TEXT,
} from 'constants/index';
import { IngredientBasicInfoCardProps } from 'interfaces';
import { flexMarginTopEndCenter, forInputLabelBg } from 'styles/commonComponentStyle';

const IngredientBasicInfoCard: FC<IngredientBasicInfoCardProps> = ({ loading, isEdit }): JSX.Element => {
  return (
    <Card sx={forInputLabelBg}>
      {loading ? (
        <DataLoader columns={12} rows={5} />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <InputController
                name="name"
                fieldType="text"
                title={COMMON_NAME_TEXT}
                isRequired
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ProductFormulationSyrupTypeSelector
                name="productFormulationSyrupType"
                title={PRODUCT_FORMULATION_SYRUP_TYPE_TEXT}
                isRequired
                disabled={loading}
              />
            </Grid>
          </Grid>
        </>
      )}

      <CheckboxGroupController
        name="type"
        loaderRows={1}
        loaderColumns={4}
        title={TYPE_TEXT}
        loading={loading}
        options={INGREDIENTS_TYPE_MAPPED}
      />

      {isEdit ? (
        <Box sx={flexMarginTopEndCenter}>
          <Button type="submit" variant="contained" id="basic-info-btn" size="small" disabled={loading}>
            {SAVE_TEXT}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default IngredientBasicInfoCard;
