import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded as AddRoundedIcon } from '@mui/icons-material';
//components
import SyrupBaseIngredientsTable from 'components/main/SyrupBaseIngredients/SyrupBaseIngredientsTable';
// constant, styles, reducer, graphql
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { ADD_NEW_TEXT, ADD_SYRUP_BASE_INGREDIENTS_ROUTE, SYRUP_BASE_INGREDIENTS_TEXT } from 'constants/index';

const SyrupBaseIngredients: FC = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Box sx={flexCenterBetween}>
        <Typography variant="h5">{SYRUP_BASE_INGREDIENTS_TEXT}</Typography>
        <Button
          color="primary"
          variant="contained"
          id="add-syrup-base-ingredient-btn"
          onClick={() => navigate(ADD_SYRUP_BASE_INGREDIENTS_ROUTE)}>
          <AddRoundedIcon />
          {ADD_NEW_TEXT}
        </Button>
      </Box>

      <SyrupBaseIngredientsTable />
    </Fragment>
  );
};

export default SyrupBaseIngredients;
