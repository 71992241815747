import { HttpStatusCode } from 'axios';
import { FC, Fragment, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
// components
import FormCard from './FormCard';
import { Alert } from 'components/common/Alert';
import { BackdropLoader } from 'components/common/BackdropLoader';
// Schema, styles, graphql, constants
import { FormulationSyrupTypeFormType } from 'interfaces';
import { useAddLoadingText, useAddTitleText } from 'hooks';
import { flexCenterBetween } from 'styles/commonComponentStyle';
import { formulationSyrupTypeSchema } from 'validationSchema';
import { ADD_TEXT, formulationSyrupTypeInitialValue, FORMULATION_SYRUP_TYPE_TEXT } from 'constants/index';
import { FormulationSyrupUnit, useCreateProductFormulationSyrupTypeMutation } from 'generated/graphql';

const Add: FC = (): JSX.Element => {
  const addText = useAddTitleText(FORMULATION_SYRUP_TYPE_TEXT);
  const creatingText = useAddLoadingText(FORMULATION_SYRUP_TYPE_TEXT);

  const navigation = useNavigate();

  const methods = useForm<FormulationSyrupTypeFormType>({
    defaultValues: formulationSyrupTypeInitialValue,
    resolver: yupResolver(formulationSyrupTypeSchema),
  });

  const { handleSubmit } = methods;

  const [createCreateProductFormulationSyrupType, { loading }] = useCreateProductFormulationSyrupTypeMutation(
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,

      onCompleted: (data) => {
        const { createProductFormulationSyrupType } = data;
        const { response } = createProductFormulationSyrupType || {};
        const { status, message } = response || {};
        if (status === HttpStatusCode.Created) {
          Alert.success(message || '');
          navigation(-1);
        }
      },

      onError: ({ message }) => {
        Alert.error(message);
      },
    },
  );

  const onSubmit: SubmitHandler<FormulationSyrupTypeFormType> = async (data) => {
    const { cost, weight, productType, productSize, subProductType, productFormulationType, name } =
      data || {};

    const { value: productTypeId } = productType || {};
    const { value: productSizeId } = productSize || {};
    const { value: subProductTypeId } = subProductType || {};
    const { value: productFormulationId } = productFormulationType || {};

    await createCreateProductFormulationSyrupType({
      variables: {
        createProductFormulationSyrupTypeInput: {
          name,
          productTypeId,
          productSizeId,
          cost: Number(cost),
          weight: Number(weight),
          unit: FormulationSyrupUnit.Mg,
          productFormulationTypeId: productFormulationId,
          ...(subProductTypeId && { subProductTypeId }),
        },
      },
    });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={flexCenterBetween}>
            <Typography variant="h5">{addText}</Typography>

            <Button variant="contained" type="submit">
              {ADD_TEXT}
            </Button>
          </Box>
          <FormCard loading={loading} />
        </form>
      </FormProvider>

      <BackdropLoader open={loading} text={creatingText} />
    </Fragment>
  );
};

export default Add;
